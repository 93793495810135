import React from 'react';
import classNames from 'classnames';

const Icon = ({ className, id }) => {
    return (
        <svg className={classNames("icon", className)}>
            <use xlinkHref={`/cdn/images/symbol-defs.svg#${id}`} />
        </svg>
    )
}

export default Icon;

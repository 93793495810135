import React from 'react';
import classNames from 'classnames';
import { Link as RRDLink } from 'react-router-dom';

import classes from './style/button.module.scss';

function LinkButton({ to, children, className = '', addClasses = [], onClick, target, disabled }) {

  let buttonClasses = addClasses.map((item) => {
    return classes[`${item}`];
  });

  buttonClasses.push(classes.button);


  return (
    <RRDLink
      target={target}
      to={to}
      className={classNames(buttonClasses, className, disabled ? classes['button-disabled'] : '')}
      onClick={(event) => onClick?.(event)}
    >
      {children}
    </RRDLink>
  );
}

export default React.memo(LinkButton);

import axios from 'axios';
import { jwtDecode } from "jwt-decode";

const user = JSON.parse(localStorage.getItem("userInfo"));
let isExpired = false;
if(user){
    var decodedToken=jwtDecode(user.token, {complete: true});
    var dateNow = new Date();

    if(decodedToken.exp < dateNow.getTime())
    {

        isExpired = true;
    }

}

const instance = isExpired ? axios.create({
    baseURL: 'https://api.surrogacy-platform.com/api/',
    timeout: 10000, // Максимальний час очікування в мілісекундах,
    headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.token}`,
        "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
    }

}) : axios.create({
    baseURL: 'https://api.surrogacy-platform.com/api',
    timeout: 10000, // Максимальний час очікування в мілісекундах,
    headers: {
        "Content-type": "application/json",
        "x-api-key":"aX4!jN9#lQ6@tR3^pM8&kW7*zV2$bB5"
    }

});

export const hostApi = 'https://api.surrogacy-platform.com'
instance.interceptors.request.use(
    (config) => {

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instance.interceptors.response.use(
    (response) => {

        return response;
    },
    (error) => {

    }
);

export default instance;

import React from "react";
import LogIn from "./components/LogIn";

import classes from "./style/formAuth.module.scss";


const FormAuth = () => {
    return (
        <div className={classes.auth_form}>
            <h1 className={classes.title}>Log In</h1>
            <LogIn />
        </div>
    );
};

export default FormAuth;

import { createStore, applyMiddleware, compose } from "redux"; // Import compose
import { thunk } from "redux-thunk"; // Import thunk directly

import rootReducer from "../reducers";

// Use compose to combine applyMiddleware and Redux DevTools extension
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension options, such as name, actionsBlacklist, etc.
    })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer, enhancer);

export default store;

import React from "react";
import ReactDOM from "react-dom/client";
import AppWrapper from "./app/Page";
import reportWebVitals from "./reportWebVitals";

import "./app/styles/app.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<AppWrapper />);

reportWebVitals();
